import Link from 'next/link';
import { removeBaseUrl } from '@/utils/url';

import { WagtailPage } from '@/types';

type Button = {
  primary: boolean;
  button_text: string;
  button_page: WagtailPage | null;
  button_url: string | null;
};

type Props = {
  buttons: Button[];
};

const Buttons = (props: Props) => {
  const { buttons } = props;
  return (
    <div className="streamfield buttons">
      {buttons.map(button => (
        <a
          key={button.button_text}
          href={
            button.button_page
              ? removeBaseUrl(button.button_page.full_url)
              : button.button_url ?? ''
          }
          className={button.primary ? 'button button--primary' : 'button'}
        >
          {button.button_text}
        </a>
      ))}
    </div>
  );
};

export default Buttons;
